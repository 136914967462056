import { createContext, useContext, useState, type FC } from 'react'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'

type ConfirmInput = {
  title?: string
  message?: string
  confirmText?: string
  isDanger?: boolean
  onConfirm: (cb: () => void) => Promise<void> | void
  textToConfirm?: string
}

type State = {
  confirm: (p: ConfirmInput) => void
}

const defaultValue: State = {
  confirm: () => null,
}

export const ConfirmContext = createContext<State>(defaultValue)
export const useConfirm = (): State => useContext(ConfirmContext)

type ConfirmProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export const ConfirmProvider: FC<ConfirmProviderProps> = (p) => {
  const [confirmInput, setConfirmInput] = useState<ConfirmInput | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  const confirm = (p: ConfirmInput) => {
    setIsLoading(false)
    setConfirmText('')
    setConfirmInput(p)
  }

  return (
    <ConfirmContext.Provider
      value={{
        confirm,
      }}
    >
      {p.children}
      <AlertDialog open={!!confirmInput}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{confirmInput?.title ?? ''}</AlertDialogTitle>
            <AlertDialogDescription>
              {confirmInput?.message}
              {confirmInput?.textToConfirm && (
                <div className='mb-2 mt-4'>
                  <div className='mb-1'>
                    Write{' '}
                    <span className='font-bold text-primary'>
                      {confirmInput?.textToConfirm}
                    </span>{' '}
                    to confirm
                  </div>
                  <Input
                    value={confirmText}
                    onChange={(e) => setConfirmText(e.target.value)}
                  />
                </div>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              onClick={(e) => {
                e.preventDefault()
                setConfirmInput(null)
              }}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              onClick={async (e) => {
                e.preventDefault()
                setIsLoading(true)
                await confirmInput?.onConfirm(() => {
                  setIsLoading(false)
                  setConfirmInput(null)
                })
              }}
              variant={confirmInput?.isDanger ? 'destructive' : 'default'}
              loading={isLoading}
              disabled={
                !!(
                  confirmInput?.textToConfirm &&
                  confirmText !== confirmInput.textToConfirm
                )
              }
            >
              {confirmInput?.confirmText ?? 'OK'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ConfirmContext.Provider>
  )
}
