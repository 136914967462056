import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'

export function CoffinReadMoreModal(p: {
  children: JSX.Element
  title: string
  description: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  return (
    <Dialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <DialogTrigger asChild>{p.children}</DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>{p.title}</DialogTitle>
          <DialogDescription className='whitespace-pre-wrap'>
            {p.description}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              p.setIsOpen(false)
            }}
          >
            Luk
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
