import Color from 'color'
import { CheckCircle2 } from 'lucide-react'

import { type Ral as RalType } from 'lib/ral-colors'
import { cn } from 'lib/utils'

export const ColorCard = (p: {
  color: RalType
  isActive: boolean
  onClick: (color: RalType) => void
}) => {
  const isLight = Color(p.color.hex).luminosity() > 0.5

  return (
    <div key={p.color.ral} className='group'>
      <div
        key={p.color.ral}
        className={cn(
          'flex aspect-square cursor-pointer items-center justify-center rounded-sm',
          isLight && 'border',
        )}
        style={{
          backgroundColor: p.color.hex,
        }}
        onClick={() => p.onClick(p.color)}
      >
        {p.isActive && (
          <CheckCircle2
            strokeWidth={2.5}
            className={cn(
              'h-4 w-4 text-primary-foreground',
              isLight && 'text-primary',
            )}
          />
        )}
      </div>
      <div
        className={cn(
          'mb-1 text-center text-xs font-thin group-hover:font-medium',
          p.isActive && 'font-bold opacity-100 group-hover:font-bold',
        )}
      >
        {p.color.ral.replace('RAL', '').trim()}
      </div>
    </div>
  )
}
