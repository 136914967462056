import {
  CameraControls,
  ContactShadows,
  Environment,
  Html,
  useProgress,
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { EffectComposer, N8AO } from '@react-three/postprocessing'
import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { Loader2 } from 'lucide-react'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Coffin } from './coffin'

const Loader = () => {
  const { errors, progress, loaded, total } = useProgress()
  if (errors.length)
    return (
      <Html center color='red'>
        Error: {JSON.stringify(errors)}
      </Html>
    )

  const procentage = ((loaded + progress / 100) / (total + 1)) * 100

  return (
    <Html center>
      <div className='flex items-center'>
        <div className='flex min-w-0 items-center gap-2 rounded-full bg-background p-2 px-3 shadow-lg'>
          <Loader2 className='h-4 w-4 animate-spin' /> <div>Loading...</div>
          <div>{procentage.toFixed(1)}%</div>
        </div>
      </div>
    </Html>
  )
}

const CoffinViewer = () => {
  const { cameraControlRef } = useCoffinEditor()

  return (
    <ErrorBoundary
      fallbackRender={(e) => {
        console.log('e', e)
        return <p>Failed</p>
      }}
    >
      <div className='fixed flex h-full w-full'>
        <Canvas camera={{ position: [-2.2, 0.52, 1.39], zoom: 2 }}>
          <Suspense fallback={<Loader />}>
            <CameraControls
              ref={cameraControlRef}
              smoothTime={0.5}
              draggingSmoothTime={0.1}
              minDistance={1.76}
              maxDistance={5.5}
              maxPolarAngle={Math.PI / 2}
            />
            <group position={[0, -0.25, 0]}>
              <Coffin />
              <ContactShadows
                frames={1}
                position={[0, 0, 0]}
                blur={0.3}
                opacity={0.15}
              />
            </group>
            <Environment
              // background
              files={['/overcast_soil_puresky_1k.hdr']}
              environmentIntensity={0.8}
              environmentRotation={[2, 2, 3]}
              backgroundBlurriness={0.07}
            />
            <EffectComposer multisampling={0}>
              <N8AO color='black' aoRadius={0.15} intensity={1.5} />
            </EffectComposer>
          </Suspense>
        </Canvas>
      </div>
    </ErrorBoundary>
  )
}

export default CoffinViewer
