import { filesize } from 'filesize'
import { UploadCloud } from 'lucide-react'
import { isMobile } from 'react-device-detect'

export const Info = (p: {
  supportedFilesText?: string
  maxFileSize?: number
  replace?: boolean
  onClick?: () => void
}) => {
  const maxFileSize = filesize(p.maxFileSize ?? 0, {
    base: 2,
    standard: 'jedec',
  })

  const maxFileSizeString = `Max: ${maxFileSize}`

  return (
    <div
      className='flex w-full cursor-pointer flex-col items-center p-3 pt-5 text-sm'
      onClick={p.onClick}
    >
      <div className='flex flex-col items-center text-center'>
        <div className='flex gap-1'>
          {!p.replace && <UploadCloud className='h-4 w-4' />}
          Tryk for at {p.replace ? 'erstatte' : 'uploade'}
        </div>
        {!isMobile && <div className='text-xs'>eller træk et billede ind</div>}
      </div>
      <div className='align-middle text-xs opacity-60'>
        {p.supportedFilesText} ({maxFileSizeString})
      </div>
    </div>
  )
}
