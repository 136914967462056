import { Decal, useTexture } from '@react-three/drei'
import { useEffect, useState } from 'react'
import * as THREE from 'three'

export const CoffinDecal = (p: {
  url: string
  pos: THREE.Vector3
  rot: THREE.Euler
  scale: number
}) => {
  const [ratio, setRatio] = useState<number | null>(null)

  useEffect(() => {
    if (!p.url) return
    const img = new Image()
    try {
      img.src = p.url
      img.onload = () => {
        setRatio(img.height / img.width)
      }
      img.onerror = (e) => {
        setRatio(null)
        console.log(e)
      }
    } catch (e) {
      console.log(e)
    }
  }, [p.url])

  if (ratio === null) return null

  return <CoffinDecalInner {...p} ratio={ratio} />
}

const CoffinDecalInner = (p: {
  url: string
  pos: THREE.Vector3
  rot: THREE.Euler
  scale: number
  ratio: number
}) => {
  const image = useTexture(p.url)

  return (
    <Decal
      position={p.pos}
      rotation={p.rot}
      scale={new THREE.Vector3(p.scale, p.scale * p.ratio, p.scale)}
      castShadow={false}
      // debug // Shows bounding box
    >
      <meshStandardMaterial
        map={image}
        polygonOffset
        polygonOffsetFactor={-10} // The material should take precedence over the original
        roughness={0.15}
        metalness={0.3}
        transparent
      />
    </Decal>
  )
}
