import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { useConfirm } from 'hooks/useConfirm'
import { useDesignCrud } from 'hooks/useDesignCrud'
import { cn } from 'lib/utils'
import { Check, Mail, Save, Settings, Trash } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'

import { GetDesignDialog } from './get-design-dialog'
import { OrderDesignDialog } from './order-design-dialog'
import { SaveDesignDialog } from './save-design-dialog'
import { ShareDesignDialog } from './share-design-dialog'
import { Badge } from './ui/badge'
import { Button } from './ui/button'

export const CurrentDesign = () => {
  const [saveDesignDialogIsOpen, setSaveDesignDialogIsOpen] = useState(false)
  const [getDesignDialogIsOpen, setGetDesignDialogIsOpen] = useState(false)
  const [orderDesignDialogIsOpen, setOrderDesignDialogIsOpen] = useState(false)
  const [shareDesignDialogIsOpen, setShareDesignDialogIsOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    clearPersistedData,
    activeDesignId,
    setActiveDesignId,
    latestDesignFromServer,
  } = useCoffinEditor()

  const paramId = searchParams.get('id')

  useEffect(() => {
    if (!paramId) return
    searchParams.delete('id') // Delete even if we don't find it
    setSearchParams(searchParams)
    setTimeout(() => {
      setActiveDesignId(paramId)
    }, 0)
  }, [paramId])

  const { confirm } = useConfirm()
  const { updateDesign: _updateDesign, isSavingDesign } = useDesignCrud()

  const updateDesign = async () => {
    confirm({
      title: 'Opdater design',
      message:
        'Er du sikker på, at du vil opdatere designet og overskrive det gemte design?',
      confirmText: 'Opdater design',
      onConfirm: async (cb) => {
        await _updateDesign({ order: false })
        cb()
      },
    })
  }

  const isReadonly = !!latestDesignFromServer?.readonly

  const status = useCallback(() => {
    switch (true) {
      case activeDesignId === null:
        return 'Udkast'
      case isReadonly:
        return 'Låst'
      case activeDesignId !== null:
        return 'Aktiv'
      default:
        return 'Udkast'
    }
  }, [activeDesignId, isReadonly])

  const onResetClick = () => {
    confirm({
      title: 'Nulstil design',
      message: 'Er du sikker på, at du vil nulstille designet?',
      confirmText: 'Nulstil',
      isDanger: true,
      onConfirm: (cb) => {
        clearPersistedData()
        cb()
      },
    })
  }

  const orderDesignDisabled = !activeDesignId || isReadonly

  const Status = () =>
    useMemo(
      () => (
        <div>
          <div className='ml-5 mr-3 flex items-center gap-2 text-xs'>
            <div className='opacity-70'>Status:</div>
            <Badge variant='outline'>{status()}</Badge>
          </div>
        </div>
      ),
      [],
    )

  const Buttons = () => (
    <>
      <Button
        variant='ghost'
        className='h-12 justify-start rounded-full hover:text-red-500 lg:justify-center'
        onClick={onResetClick}
      >
        <Trash className='mr-2 h-4 w-4' />
        Nulstil
      </Button>
      <Button
        variant='ghost'
        className={cn(
          'h-12 justify-start rounded-full lg:justify-center',
          !activeDesignId && 'opacity-50',
        )}
        onClick={() =>
          orderDesignDisabled
            ? toast.error('Du skal gemme dit design før det kan deles')
            : setShareDesignDialogIsOpen(true)
        }
      >
        <Mail className='mr-2 h-4 w-4' />
        Del design
      </Button>
      <Button
        variant='ghost'
        className={cn(
          'h-12 justify-start rounded-full lg:justify-center',
          orderDesignDisabled && 'opacity-50',
        )}
        onClick={() =>
          orderDesignDisabled
            ? toast.error('Du skal gemme dit design før det kan indsendes')
            : setOrderDesignDialogIsOpen(true)
        }
      >
        <Check className='mr-2 h-4 w-4' />
        Indsend
      </Button>
      <Button
        className='h-12 justify-start lg:justify-center lg:rounded-full'
        loading={isSavingDesign}
        disabled={isReadonly}
        onClick={() =>
          activeDesignId ? updateDesign() : setSaveDesignDialogIsOpen(true)
        }
      >
        <Save className='mr-2 h-4 w-4' />
        {activeDesignId ? 'Opdater design' : 'Gem design'}
      </Button>
    </>
  )

  const MobileMenu = () => {
    return (
      <div>
        <Popover>
          <PopoverTrigger asChild>
            <Button className='rounded-full' variant='secondary'>
              <Settings className='mr-2 h-4 w-4' />
              Indstillinger
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className={cn(
              'm-2 mb-0 flex w-80 flex-col gap-2 rounded-xl bg-background p-2 shadow-lg',
            )}
          >
            <div className='flex justify-center border-b pb-4 pt-2'>
              <Status />
            </div>
            <Buttons />
          </PopoverContent>
        </Popover>
      </div>
    )
  }

  return (
    <>
      <div className='fixed bottom-2 left-2 z-10 flex lg:hidden'>
        <MobileMenu />
      </div>
      <div
        className={cn(
          'pointer-events-none fixed bottom-0 left-0 right-0 z-10 hidden w-full justify-center pb-4 lg:flex',
        )}
      >
        <div
          className={cn(
            'pointer-events-auto flex items-center gap-2 rounded-full bg-white p-2 shadow-lg dark:bg-slate-900',
          )}
        >
          <Status />
          <div className='h-8 border-l' />
          <Buttons />
        </div>
        <SaveDesignDialog
          isOpen={saveDesignDialogIsOpen}
          setIsOpen={setSaveDesignDialogIsOpen}
        />
        <GetDesignDialog
          isOpen={getDesignDialogIsOpen}
          setIsOpen={setGetDesignDialogIsOpen}
        />
        <OrderDesignDialog
          isOpen={orderDesignDialogIsOpen}
          setIsOpen={setOrderDesignDialogIsOpen}
        />
        <ShareDesignDialog
          isOpen={shareDesignDialogIsOpen}
          setIsOpen={setShareDesignDialogIsOpen}
        />
        {activeDesignId && (
          <div className='pointer-events-auto fixed bottom-1 left-1 text-xs opacity-30'>
            ID: {activeDesignId}
          </div>
        )}
      </div>
    </>
  )
}
