import { useState } from 'react'
import { toast } from 'sonner'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { useApi } from 'hooks/useApi'
import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { EasyForm } from './easy-form'

export type ShareInfo = {
  email: string
}

export const ShareDesignDialog = (p: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { activeDesignId } = useCoffinEditor()
  const { shareDesign } = useApi()
  const [isSharingDesign, setIsSharingDesign] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = async (values: Partial<ShareInfo>) => {
    if (!activeDesignId) {
      toast.error('Mangler id')
      setErrorMessage('Mangler id')
      return
    }
    if (!values.email) {
      toast.error('Email er påkrævet')
      setErrorMessage('Email er påkrævet')
      return
    }

    setIsSharingDesign(true)
    try {
      await shareDesign(activeDesignId, values.email)
      toast.success('Designet blev delt succesfuldt')
      p.setIsOpen(false)
    } catch (e) {
      console.error(e)
      setErrorMessage((e as { message: string }).message)
      toast.error('Der skete en fejl')
    }
    setIsSharingDesign(false)
  }

  return (
    <AlertDialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Del dit design</AlertDialogTitle>
          <AlertDialogDescription>
            <div className='mb-4'>
              Skriv email-adressen på den person, du vil dele designet med. De
              vil modtage en email med et link til designet.
            </div>
            {errorMessage && (
              <div className='mb-4 text-red-500'>{errorMessage}</div>
            )}
            <EasyForm<ShareInfo>
              formFields={{
                email: {
                  type: 'input',
                  label: 'Email',
                  validate: (yup) =>
                    yup
                      .string()
                      .email('Emailen er ikke gyldig')
                      .required('Email er påkrævet'),
                },
              }}
              onSubmit={onSubmit}
              loading={isSharingDesign}
              submitButtonText='Del'
              cancelButtonText='Annuller'
              onCancel={() => p.setIsOpen(false)}
            />
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  )
}
