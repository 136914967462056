import { useCoffinEditor } from 'hooks/useCoffinEditor'

import { Card } from './card'
import { Title } from './title'

export const Handles = () => {
  const {
    model,
    handles,
    setHandles,
    handleCount,
    setHandleCount,
    setRalCodeHandles,
  } = useCoffinEditor()

  const showHandleCount =
    ['flex', 'ligb'].includes(model) && ['greb', 'stropper'].includes(handles)
  const showVariants = ['flex', 'ligb'].includes(model)

  return (
    <div className='p-3'>
      <div className='grid grid-cols-2 gap-2'>
        <Card
          imageUrl={'/greb.png'}
          title={'Greb'}
          isActive={handles === 'greb'}
          onClick={() => {
            setHandleCount(6)
            setHandles('greb')
          }}
        />
        {showVariants && (
          <>
            <Card
              imageUrl={'/stropper.png'}
              title={'Stropper'}
              isActive={handles === 'stropper'}
              onClick={() => {
                setHandleCount(6)
                setRalCodeHandles('RAL 9010')
                setHandles('stropper')
              }}
            />
            {/* <Card
              imageUrl={'/baerestang.png'}
              title={'Bærestænger'}
              isActive={handles === 'baerestaenger'}
              onClick={() => {
                setHandleCount(6)
                setHandles('baerestaenger')
              }}
            /> */}
          </>
        )}
      </div>
      {showHandleCount && (
        <>
          <Title className='mt-6'>Antal</Title>
          <div className='grid grid-cols-2 gap-2'>
            <Card
              title={'6'}
              isActive={handleCount === 6}
              onClick={() => setHandleCount(6)}
            />
            {handles !== 'stropper' && (
              <Card
                title={'8'}
                isActive={handleCount === 8}
                onClick={() => setHandleCount(8)}
              />
            )}
            <Card
              title={'10'}
              isActive={handleCount === 10}
              onClick={() => setHandleCount(10)}
            />
          </div>
        </>
      )}
    </div>
  )
}
