import { TableCell, TableRow } from 'components/ui/table'
import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { BooleanFilter } from 'lib/boolean-filter'
import { formatHandleName } from 'lib/formatHandleName'
import { capitalize } from 'lodash'

export const CurrentDesignRows = () => {
  const {
    model,
    handles,
    handleCount,
    ral,
    prints,
    ralLid,
    ralCodeLidActive,
    ralTopPitch,
    ralCodeTopPitchActive,
    ralPlinth,
    ralCodePlinthActive,
    ralHandles,
    ralCodeHandlesActive,
  } = useCoffinEditor()

  const print = Object.entries(prints)
    .filter(([, value]) => !!value?.fileItem?.url)
    .map(([key]) => key)
    .join(', ')

  const isOrbit = model === 'orbit'

  const extraColors = [
    {
      label: 'Lågtopfarve',
      ral: ralLid,
      isActive: ralCodeLidActive,
    },
    !isOrbit && {
      label: 'Lågsidefarve',
      ral: ralTopPitch,
      isActive: ralCodeTopPitchActive,
    },
    !isOrbit && {
      label: 'Sokkelfarve',
      ral: ralPlinth,
      isActive: ralCodePlinthActive,
    },
    {
      label: 'Grebsfarve',
      ral: ralHandles,
      isActive: ralCodeHandlesActive,
    },
  ]
    .filter(BooleanFilter)
    .filter((color) => color.isActive)

  return (
    <>
      <TableRow>
        <TableCell className='font-bold'>Kiste</TableCell>
        <TableCell>{capitalize(model)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className='font-bold'>Greb</TableCell>
        <TableCell>
          {['greb', 'stropper'].includes(handles)
            ? `${handleCount} x ${handles}`
            : formatHandleName(handles)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className='font-bold'>Grundfarve</TableCell>
        <TableCell>
          <div className='flex items-center gap-2'>
            <div
              className='max-h-6 min-h-6 min-w-6 max-w-6 rounded-md border'
              style={{
                backgroundColor: ral.hex,
              }}
            />
            {capitalize(ral.ral)}
          </div>
        </TableCell>
      </TableRow>
      {extraColors.map(({ label, ral: extraRal }, i) => (
        <TableRow key={i}>
          <TableCell className='font-bold'>{label}</TableCell>
          <TableCell>
            <div className='flex items-center gap-2'>
              <div
                className='max-h-6 min-h-6 min-w-6 max-w-6 rounded-md border'
                style={{
                  backgroundColor: extraRal.hex,
                }}
              />
              {capitalize(extraRal.ral)}
            </div>
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell className='font-bold'>Tryk</TableCell>
        <TableCell>{!print ? 'Intet tryk' : capitalize(print)}</TableCell>
      </TableRow>
    </>
  )
}
