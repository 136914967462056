import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { BooleanFilter } from 'lib/boolean-filter'
import { formatHandleName } from 'lib/formatHandleName'
import { cn } from 'lib/utils'
import { capitalize } from 'lodash'
import { Brush } from 'lucide-react'

import { Button } from '../ui/button'
import { CoffinModel } from './coffin-model'
import { Handles } from './handles'
import { MenuDropDown } from './menu-drop-down'
import { MenuDropDownDialog } from './menu-drop-down-dialog'
import { Ral } from './ral'
import { SideSelector } from './side-selector'

export const Menu = () => {
  const {
    model,
    handles,
    handleCount,
    ral,
    ralLid,
    ralCodeLidActive,
    ralTopPitch,
    ralCodeTopPitchActive,
    ralPlinth,
    ralCodePlinthActive,
    ralHandles,
    ralCodeHandlesActive,
    prints,
    latestDesignFromServer,
  } = useCoffinEditor()

  const printCount = Object.values(prints).filter(
    (print) => !!print?.fileItem?.url,
  ).length

  const handleimg =
    {
      greb: '/greb.png',
      stropper: '/stropper.png',
      baerestaenger: '/baerestang.png',
    }[handles] ?? '/greb.png'
  const modelimg =
    {
      orbit: '/orbit.png',
      flex: '/flex.png',
      ligb: '/ligb.png',
    }[model] ?? '/flex.png'
  const modelName =
    {
      orbit: 'Orbit',
      flex: 'Flex',
      ligb: 'Kremering',
    }[model] ?? 'Flex'
  const printUrls = Object.values(prints)
    .map((print) => print?.fileItem?.thumbnailUrl)
    .filter(BooleanFilter)

  const isOrbit = model === 'orbit'

  const colors = [
    {
      ral,
      isActive: true,
    },
    {
      ral: ralLid,
      isActive: ralCodeLidActive,
    },
    !isOrbit && {
      ral: ralTopPitch,
      isActive: ralCodeTopPitchActive,
    },
    !isOrbit && {
      ral: ralPlinth,
      isActive: ralCodePlinthActive,
    },
    {
      ral: ralHandles,
      isActive: ralCodeHandlesActive,
    },
  ]
    .filter(BooleanFilter)
    .filter((color) => color.ral && color.isActive)

  const isReadonly = !!latestDesignFromServer?.readonly

  const MobileMenu = () => {
    return (
      <div>
        <Popover>
          <PopoverTrigger asChild>
            <Button className='rounded-full' variant='secondary'>
              <Brush className='mr-2 h-4 w-4' />
              Design
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className={cn(
              'm-2 mb-0 flex w-80 flex-col gap-2 rounded-xl bg-background p-2 shadow-lg',
            )}
          >
            <>
              <MenuDropDownDialog
                label={'Kiste'}
                content={(close) => <CoffinModel close={close} />}
                value={capitalize(modelName)}
                preview={
                  <img
                    src={modelimg}
                    alt=''
                    className='h-full w-full object-cover'
                  />
                }
                popoverClassName='w-80'
                buttonClassName='min-w-[12rem]'
                readonly={isReadonly}
              />
              <MenuDropDownDialog
                label={'Farve'}
                content={(close) => <Ral close={close} />}
                value={capitalize(ral.ral)}
                preview={
                  <div
                    className={cn(
                      'flex h-12 w-12 gap-[1px] overflow-hidden rounded-full border bg-stone-100',
                    )}
                  >
                    {colors.map((color, index) => (
                      <div
                        key={index}
                        className='h-full w-full'
                        style={{
                          backgroundColor: color.ral.hex,
                        }}
                      />
                    ))}
                  </div>
                }
                buttonClassName='min-w-[12rem]'
                readonly={isReadonly}
              />
              <MenuDropDownDialog
                label={'Greb'}
                content={<Handles />}
                value={
                  ['greb', 'stropper'].includes(handles)
                    ? `${handleCount} x ${handles}`
                    : formatHandleName(handles)
                }
                preview={
                  <img
                    src={handleimg}
                    alt=''
                    className='h-full w-full object-cover'
                  />
                }
                readonly={isReadonly}
              />
              <MenuDropDownDialog
                label={'Print'}
                content={(close) => <SideSelector close={close} />}
                value={
                  !printCount
                    ? 'Intet tryk'
                    : `${printCount} side${printCount === 1 ? '' : 'r'}`
                }
                preview={
                  <div
                    className={cn(
                      'grid h-12 w-12 gap-[1px] overflow-hidden rounded-full border bg-stone-900',
                      printUrls.length === 0 && 'bg-stone-50',
                    )}
                    style={{
                      gridTemplateColumns: `repeat(${printUrls.length}, minmax(0, 1fr))`,
                    }}
                  >
                    {printUrls.map((url, index) => (
                      <img
                        key={index}
                        src={url}
                        alt=''
                        className='h-full w-full object-cover'
                      />
                    ))}
                  </div>
                }
                popoverClassName='w-80'
                buttonClassName='min-w-[12rem]'
              />
            </>
          </PopoverContent>
        </Popover>
      </div>
    )
  }

  return (
    <>
      <div className='fixed bottom-14 left-2 z-10 flex lg:hidden'>
        <MobileMenu />
      </div>
      <div
        className={cn(
          'pointer-events-none fixed left-0 right-0 top-0 z-10 hidden w-full justify-center pt-4 lg:flex',
        )}
      >
        <div
          className={cn(
            'pointer-events-auto flex items-center gap-3 rounded-full bg-white p-2 shadow-lg dark:bg-slate-900',
          )}
        >
          <>
            <MenuDropDown
              label={'Kiste'}
              content={(close) => <CoffinModel close={close} />}
              value={capitalize(modelName)}
              preview={
                <img
                  src={modelimg}
                  alt=''
                  className='h-full w-full object-cover'
                />
              }
              popoverClassName='w-[60rem]'
              buttonClassName='min-w-[12rem]'
              readonly={isReadonly}
            />
            <MenuDropDown
              label={'Farve'}
              content={(close) => <Ral close={close} />}
              value={capitalize(ral.ral)}
              preview={
                <div
                  className={cn(
                    'flex h-12 w-12 gap-[1px] overflow-hidden rounded-full border bg-stone-100',
                  )}
                >
                  {colors.map((color, index) => (
                    <div
                      key={index}
                      className='h-full w-full'
                      style={{
                        backgroundColor: color.ral.hex,
                      }}
                    />
                  ))}
                </div>
              }
              buttonClassName='min-w-[12rem]'
              readonly={isReadonly}
            />
            <MenuDropDown
              label={'Greb'}
              content={<Handles />}
              value={
                ['greb', 'stropper'].includes(handles)
                  ? `${handleCount} x ${handles}`
                  : formatHandleName(handles)
              }
              preview={
                <img
                  src={handleimg}
                  alt=''
                  className='h-full w-full object-cover'
                />
              }
              readonly={isReadonly}
            />
            <MenuDropDown
              label={'Print'}
              content={(close) => <SideSelector close={close} />}
              value={
                !printCount
                  ? 'Intet tryk'
                  : `${printCount} side${printCount === 1 ? '' : 'r'}`
              }
              preview={
                <div
                  className={cn(
                    'grid h-12 w-12 gap-[1px] overflow-hidden rounded-full border bg-stone-900',
                    printUrls.length === 0 && 'bg-stone-50',
                  )}
                  style={{
                    gridTemplateColumns: `repeat(${printUrls.length}, minmax(0, 1fr))`,
                  }}
                >
                  {printUrls.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt=''
                      className='h-full w-full object-cover'
                    />
                  ))}
                </div>
              }
              popoverClassName='w-[38rem]'
              buttonClassName='min-w-[12rem]'
              align='end'
            />
          </>
        </div>
      </div>
    </>
  )
}
