import { type ReactNode } from 'react'
import {
  DragDropContext,
  Draggable,
  type DropResult,
} from 'react-beautiful-dnd'

import { StrictModeDroppable } from './strict-mode-droppable'

export const reorder = <T,>(
  list: T[],
  startIndex: number,
  endIndex: number,
) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  removed && result.splice(endIndex, 0, removed)

  return result
}

const ReorderableList = <T extends { id: string }>(p: {
  items: T[]
  onReorder: (items: T[]) => void
  renderItem: (item: T, isLast: boolean) => ReactNode
  footerElement?: ReactNode
  readonly?: boolean
}) => {
  const onReorderEnd = (result: DropResult) => {
    if (p.readonly) return

    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newOrder = reorder(
      p.items,
      result.source.index,
      result.destination.index,
    )

    p.onReorder(newOrder)
  }

  return (
    <div
      className='flex w-full flex-col overflow-hidden'
      onClick={(e) => e.stopPropagation()}
    >
      <DragDropContext onDragEnd={onReorderEnd}>
        <StrictModeDroppable droppableId='droppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {(p.items || []).map((item, i) => (
                <Draggable key={item.id} draggableId={item.id} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {p.renderItem(item, i === p.items.length - 1)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
        {p.footerElement}
      </DragDropContext>
    </div>
  )
}

export default ReorderableList
