import autoAnimate from '@formkit/auto-animate'
import { useQuery } from '@tanstack/react-query'
import { useApi } from 'hooks/useApi'
import { useCoffinEditor, type Model } from 'hooks/useCoffinEditor'
import { useDesignCrud } from 'hooks/useDesignCrud'
import { ChevronLeft } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'

import { Button } from '../ui/button'
import { Card } from './card'
import { CoffinReadMoreModal } from './coffin-read-more-modal'

const ORBIT_DESCRIPTION = `Orbit kisten er Danmarks mest bæredygtige kiste. Materialet består af 80% mindre træ end en normal trækiste. Når der produceres 1 trækiste, kan der produceres 5 Orbit kister. Orbit er 100% fri for maling, metal, lak og kemi og vejer kun 12 kg.`

const ORIBT_MORE_DESCRIPTION = `Kistens vægt: 12 kg
Højde: 41cm
Længde: 208cm
Bredde: 65cm

Alle vores alm. størrelse kister kan tage op til: 120kg`

const FLEX_DESCRIPTION = `Flexkisten er både en begravelses- og kremeringskiste.
              
Alle vores Flexkister er af spån og MDF, hvorpå der er lagt papir. Dette gør, at vi kun skal lakere vores kister én gang, hvilket er et stort hensyn til miljøet.`

const FLEX_MORE_DESCRIPTION = `Kistens Vægt: 45 kg
Mål: xxx
Vægten kan bærer op til:  xxx`

const LIGB_DESCRIPTION = `Alle vores kremeringskister er af spån og MDF, hvorpå der er lagt papir. Dette gør, at vi kun skal lakere vores kister én gang, hvilket er et stort hensyn til miljøet.`

const LIGB_MORE_DESCRIPTION = `Kistens Vægt: 42 kg
Mål: xxx
Vægten kan bærer op til: xxx`

export const CoffinModel = (p: { close: () => void }) => {
  const { model, setModel } = useCoffinEditor()
  const { getDesign } = useDesignCrud()
  const [activePreview, setActivePreview] = useState<Model | null>(null)
  const { getSuggestions } = useApi()
  const [loadingDesign, setLoadingDesign] = useState<string | null>(null)
  const parent = useRef(null)

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  const { data: suggestions } = useQuery({
    queryKey: ['suggestions'],
    queryFn: getSuggestions,
  })

  const SuggestionButton = ({
    model,
    title,
    readMoreDescription,
  }: {
    model: Model
    title: string
    readMoreDescription: string
  }) => {
    const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)

    return (
      <div className='items flex flex-grow flex-col items-end justify-end gap-2 p-2 md:flex-row'>
        <CoffinReadMoreModal
          isOpen={readMoreIsOpen}
          setIsOpen={setReadMoreIsOpen}
          title={title}
          description={readMoreDescription}
        >
          <Button
            variant='outline'
            className='w-full md:w-auto'
            onClick={(e) => {
              e.stopPropagation()
              setReadMoreIsOpen(true)
            }}
          >
            Læs mere
          </Button>
        </CoffinReadMoreModal>
        <Button
          className='w-full md:w-auto'
          onClick={(e) => {
            e.stopPropagation()
            setActivePreview(model)
          }}
        >
          Se forslag
        </Button>
      </div>
    )
  }

  const modelNames = {
    orbit: 'Orbit',
    flex: 'Flexkiste',
    ligb: 'Kremeringskiste',
  }

  return (
    <div className='p-3' ref={parent}>
      {!activePreview && (
        <div className='grid grid-cols-2 gap-2 lg:grid-cols-3'>
          <Card
            imageUrl={'/orbit.png'}
            title={modelNames.orbit}
            isActive={model === 'orbit'}
            onClick={() => {
              setModel('orbit')
              p.close()
            }}
            description={ORBIT_DESCRIPTION}
            footer={
              <SuggestionButton
                model='orbit'
                title='Orbit'
                readMoreDescription={ORIBT_MORE_DESCRIPTION}
              />
            }
            hideTextOnSmallScreens
          />
          <Card
            imageUrl={'/flex.png'}
            title={modelNames.flex}
            isActive={model === 'flex'}
            onClick={() => {
              setModel('flex')
              p.close()
            }}
            description={FLEX_DESCRIPTION}
            footer={
              <SuggestionButton
                model='flex'
                title='Flexkiste'
                readMoreDescription={FLEX_MORE_DESCRIPTION}
              />
            }
            hideTextOnSmallScreens
          />
          <Card
            imageUrl={'/ligb.png'}
            title={modelNames.ligb}
            isActive={model === 'ligb'}
            onClick={() => {
              setModel('ligb')
              p.close()
            }}
            description={LIGB_DESCRIPTION}
            footer={
              <SuggestionButton
                model='ligb'
                title='Kremeringskiste'
                readMoreDescription={LIGB_MORE_DESCRIPTION}
              />
            }
            hideTextOnSmallScreens
          />
        </div>
      )}

      {activePreview && (
        <>
          <Button
            className='mb-3 rounded-full'
            onClick={() => setActivePreview(null)}
          >
            <ChevronLeft className='mr-2 h-4 w-4' />
            Vælg kiste
          </Button>
          <div className='grid grid-cols-2 gap-2 lg:grid-cols-3'>
            {suggestions?.results
              .filter((s) => s.type === activePreview)
              .map((suggestion, i) => (
                <Card
                  key={i}
                  imageUrl={suggestion.previewImageUrl}
                  title={suggestion.title}
                  isLoading={loadingDesign === suggestion.designId}
                  footer={
                    <Button
                      variant='outline'
                      className='m-2'
                      onClick={async () => {
                        setLoadingDesign(suggestion.designId)
                        try {
                          await getDesign(suggestion.designId, true)
                        } catch (e) {
                          toast.error('Der opstod en fejl. Prøv venligst igen')
                        }
                        setLoadingDesign(null)
                        p.close()
                      }}
                    >
                      Vælg udgave
                    </Button>
                  }
                />
              ))}
          </div>
        </>
      )}
    </div>
  )
}
