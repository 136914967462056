export type RalCode = (typeof RAL_COLORS)[number]['ral']
export type Ral = (typeof RAL_COLORS)[number]

export const RAL_COLORS = [
  { ral: 'RAL 1000', hex: '#CCC58F', name: 'Green beige' },
  { ral: 'RAL 1001', hex: '#D1BC8A', name: 'Beige' },
  { ral: 'RAL 1002', hex: '#D2B773', name: 'Sand yellow' },
  { ral: 'RAL 1003', hex: '#F7BA0B', name: 'Signal yellow' },
  { ral: 'RAL 1004', hex: '#E2B007', name: 'Golden yellow' },
  { ral: 'RAL 1005', hex: '#C89F04', name: 'Honey yellow' },
  { ral: 'RAL 1006', hex: '#E1A100', name: 'Maize yellow' },
  { ral: 'RAL 1007', hex: '#E79C00', name: 'Daffodil yellow' },
  { ral: 'RAL 1011', hex: '#AF8A54', name: 'Brown beige' },
  { ral: 'RAL 1012', hex: '#D9C022', name: 'Lemon yellow' },
  { ral: 'RAL 1013', hex: '#E9E5CE', name: 'Oyster white' },
  { ral: 'RAL 1014', hex: '#DFCEA1', name: 'Ivory' },
  { ral: 'RAL 1015', hex: '#EADEBD', name: 'Light ivory' },
  { ral: 'RAL 1016', hex: '#EAF044', name: 'Sulfur yellow' },
  { ral: 'RAL 1017', hex: '#F4B752', name: 'Saffron yellow' },
  { ral: 'RAL 1018', hex: '#F3E03B', name: 'Zinc yellow' },
  { ral: 'RAL 1019', hex: '#A4957D', name: 'Grey beige' },
  { ral: 'RAL 1020', hex: '#9A9464', name: 'Olive yellow' },
  { ral: 'RAL 1021', hex: '#EEC900', name: 'Rape yellow' },
  { ral: 'RAL 1023', hex: '#F0CA00', name: 'traffic yellow' },
  { ral: 'RAL 1024', hex: '#B89C50', name: 'Ochre yellow' },
  { ral: 'RAL 1026', hex: '#F5FF00', name: 'Luminous yellow' },
  { ral: 'RAL 1027', hex: '#A38C15', name: 'Curry' },
  { ral: 'RAL 1028', hex: '#FFAB00', name: 'Melon yellow' },
  { ral: 'RAL 1032', hex: '#DDB20F', name: 'Broom yellow' },
  { ral: 'RAL 1033', hex: '#FAAB21', name: 'Dahlia yellow' },
  { ral: 'RAL 1034', hex: '#EDAB56', name: 'Pastel yellow' },
  { ral: 'RAL 1035', hex: '#A29985', name: 'Pearl beige' },
  { ral: 'RAL 1036', hex: '#927549', name: 'Pearl gold' },
  { ral: 'RAL 1037', hex: '#EEA205', name: 'Sun yellow' },
  { ral: 'RAL 2000', hex: '#DD7907', name: 'Yellow orange' },
  { ral: 'RAL 2001', hex: '#BE4E20', name: 'Red orange' },
  { ral: 'RAL 2002', hex: '#C63927', name: 'Vermillion' },
  { ral: 'RAL 2003', hex: '#FA842B', name: 'Pastel orange' },
  { ral: 'RAL 2004', hex: '#E75B12', name: 'Pure orange' },
  { ral: 'RAL 2005', hex: '#FF2300', name: 'Luminous orange' },
  { ral: 'RAL 2007', hex: '#FFA421', name: 'Luminous bright orange' },
  { ral: 'RAL 2008', hex: '#F3752C', name: 'Bright red orange' },
  { ral: 'RAL 2009', hex: '#E15501', name: 'traffic orange' },
  { ral: 'RAL 2010', hex: '#D4652F', name: 'Signal orange' },
  { ral: 'RAL 2011', hex: '#EC7C25', name: 'Deep orange' },
  { ral: 'RAL 2012', hex: '#DB6A50', name: 'Salmon orange' },
  { ral: 'RAL 2013', hex: '#954527', name: 'Pearl orange' },
  { ral: 'RAL 3000', hex: '#AB2524', name: 'Flame red' },
  { ral: 'RAL 3001', hex: '#A02128', name: 'Signal red' },
  { ral: 'RAL 3002', hex: '#A1232B', name: 'Carmine red' },
  { ral: 'RAL 3003', hex: '#8D1D2C', name: 'Ruby red' },
  { ral: 'RAL 3004', hex: '#701F29', name: 'Purple red' },
  { ral: 'RAL 3005', hex: '#5E2028', name: 'Wine red' },
  { ral: 'RAL 3007', hex: '#402225', name: 'Black red' },
  { ral: 'RAL 3009', hex: '#703731', name: 'Oxide red' },
  { ral: 'RAL 3011', hex: '#7E292C', name: 'Brown red' },
  { ral: 'RAL 3012', hex: '#CB8D73', name: 'Beige red' },
  { ral: 'RAL 3013', hex: '#9C322E', name: 'Tomato red' },
  { ral: 'RAL 3014', hex: '#D47479', name: 'Antique pink' },
  { ral: 'RAL 3015', hex: '#E1A6AD', name: 'Light pink' },
  { ral: 'RAL 3016', hex: '#AC4034', name: 'Coral red' },
  { ral: 'RAL 3017', hex: '#D3545F', name: 'Rose' },
  { ral: 'RAL 3018', hex: '#D14152', name: 'Strawberry red' },
  { ral: 'RAL 3020', hex: '#C1121C', name: 'traffic red' },
  { ral: 'RAL 3022', hex: '#D56D56', name: 'Salmon pink' },
  { ral: 'RAL 3024', hex: '#F70000', name: 'Luminous red' },
  { ral: 'RAL 3026', hex: '#FF0000', name: 'Luminous bright red' },
  { ral: 'RAL 3027', hex: '#B42041', name: 'Raspberry red' },
  { ral: 'RAL 3028', hex: '#E72512', name: 'Pure red' },
  { ral: 'RAL 3031', hex: '#AC323B', name: 'Orient red' },
  { ral: 'RAL 3032', hex: '#711521', name: 'Pearl ruby red' },
  { ral: 'RAL 3033', hex: '#B24C43', name: 'Pearl pink' },
  { ral: 'RAL 4001', hex: '#8A5A83', name: 'Red lilac' },
  { ral: 'RAL 4002', hex: '#933D50', name: 'Red violet' },
  { ral: 'RAL 4003', hex: '#D15B8F', name: 'Heather violet' },
  { ral: 'RAL 4004', hex: '#691639', name: 'Claret violet' },
  { ral: 'RAL 4005', hex: '#83639D', name: 'Blue lilac' },
  { ral: 'RAL 4006', hex: '#992572', name: 'Traffic purple' },
  { ral: 'RAL 4007', hex: '#4A203B', name: 'Purple violet' },
  { ral: 'RAL 4008', hex: '#904684', name: 'Signal violet' },
  { ral: 'RAL 4009', hex: '#A38995', name: 'Pastel violet' },
  { ral: 'RAL 4010', hex: '#C63678', name: 'Telemagenta' },
  { ral: 'RAL 4011', hex: '#8773A1', name: 'Pearl violet' },
  { ral: 'RAL 4012', hex: '#6B6880', name: 'Pearl blackberry' },
  { ral: 'RAL 5000', hex: '#384C70', name: 'Violet blue' },
  { ral: 'RAL 5001', hex: '#1F4764', name: 'Green blue' },
  { ral: 'RAL 5002', hex: '#2B2C7C', name: 'Ultramarine blue' },
  { ral: 'RAL 5003', hex: '#2A3756', name: 'Sapphire blue' },
  { ral: 'RAL 5004', hex: '#1D1F2A', name: 'Black blue' },
  { ral: 'RAL 5005', hex: '#154889', name: 'Signal blue' },
  { ral: 'RAL 5007', hex: '#41678D', name: 'Brilliant blue' },
  { ral: 'RAL 5008', hex: '#313C48', name: 'Grey blue' },
  { ral: 'RAL 5009', hex: '#2E5978', name: 'Azure blue' },
  { ral: 'RAL 5010', hex: '#13447C', name: 'Gentian blue' },
  { ral: 'RAL 5011', hex: '#232C3F', name: 'Steel blue' },
  { ral: 'RAL 5012', hex: '#3481B8', name: 'Light blue' },
  { ral: 'RAL 5013', hex: '#232D53', name: 'Cobalt blue' },
  { ral: 'RAL 5014', hex: '#6C7C98', name: 'Pigeon blue' },
  { ral: 'RAL 5015', hex: '#2874B2', name: 'Sky blue' },
  { ral: 'RAL 5017', hex: '#0E518D', name: 'Traffic blue' },
  { ral: 'RAL 5018', hex: '#21888F', name: 'Turquoise blue' },
  { ral: 'RAL 5019', hex: '#1A5784', name: 'Capri blue' },
  { ral: 'RAL 5020', hex: '#0B4151', name: 'Ocean blue' },
  { ral: 'RAL 5021', hex: '#07737A', name: 'Water blue' },
  { ral: 'RAL 5022', hex: '#2F2A5A', name: 'Night blue' },
  { ral: 'RAL 5023', hex: '#4D668E', name: 'Distant blue' },
  { ral: 'RAL 5024', hex: '#6A93B0', name: 'Pastel blue' },
  { ral: 'RAL 5025', hex: '#296478', name: 'Pearl Gentian blue' },
  { ral: 'RAL 5026', hex: '#102C54', name: 'Pearl night blue' },
  { ral: 'RAL 6000', hex: '#327662', name: 'Patina green' },
  { ral: 'RAL 6001', hex: '#28713E', name: 'Emerald green' },
  { ral: 'RAL 6002', hex: '#276235', name: 'Leaf green' },
  { ral: 'RAL 6003', hex: '#4B573E', name: 'Olive Green' },
  { ral: 'RAL 6004', hex: '#0E4243', name: 'Blue green' },
  { ral: 'RAL 6005', hex: '#0F4336', name: 'Moss green' },
  { ral: 'RAL 6006', hex: '#40433B', name: 'Grey olive' },
  { ral: 'RAL 6007', hex: '#283424', name: 'Bottle green' },
  { ral: 'RAL 6008', hex: '#35382E', name: 'Brown green' },
  { ral: 'RAL 6009', hex: '#26392F', name: 'Fir green' },
  { ral: 'RAL 6010', hex: '#3E753B', name: 'Grass green' },
  { ral: 'RAL 6011', hex: '#68825B', name: 'Reseda green' },
  { ral: 'RAL 6012', hex: '#31403D', name: 'Black green' },
  { ral: 'RAL 6013', hex: '#797C5A', name: 'Reed green' },
  { ral: 'RAL 6014', hex: '#444337', name: 'Yellow olive' },
  { ral: 'RAL 6015', hex: '#3D403A', name: 'Black olive' },
  { ral: 'RAL 6016', hex: '#026A52', name: 'Turquoise green' },
  { ral: 'RAL 6017', hex: '#468641', name: 'May green' },
  { ral: 'RAL 6018', hex: '#48A43F', name: 'Yellow green' },
  { ral: 'RAL 6019', hex: '#B7D9B1', name: 'pastel green' },
  { ral: 'RAL 6020', hex: '#354733', name: 'Chrome green' },
  { ral: 'RAL 6021', hex: '#86A47C', name: 'Pale green' },
  { ral: 'RAL 6022', hex: '#3E3C32', name: 'Brown olive' },
  { ral: 'RAL 6024', hex: '#008754', name: 'Traffic green' },
  { ral: 'RAL 6025', hex: '#53753C', name: 'Fern green' },
  { ral: 'RAL 6026', hex: '#005D52', name: 'Opal green' },
  { ral: 'RAL 6027', hex: '#81C0BB', name: 'Light green' },
  { ral: 'RAL 6028', hex: '#2D5546', name: 'Pine green' },
  { ral: 'RAL 6029', hex: '#007243', name: 'Mint green' },
  { ral: 'RAL 6032', hex: '#0F8558', name: 'Signal green' },
  { ral: 'RAL 6033', hex: '#478A84', name: 'Mint turquoise' },
  { ral: 'RAL 6034', hex: '#7FB0B2', name: 'Pastel turquoise' },
  { ral: 'RAL 6035', hex: '#1B542C', name: 'Pearl green' },
  { ral: 'RAL 6036', hex: '#005D4C', name: 'Pearl opal green' },
  { ral: 'RAL 6037', hex: '#25E712', name: 'Pure green' },
  { ral: 'RAL 6038', hex: '#00F700', name: 'Luminous green' },
  { ral: 'RAL 7000', hex: '#7E8B92', name: 'Squirrel grey' },
  { ral: 'RAL 7001', hex: '#8F999F', name: 'Silver grey' },
  { ral: 'RAL 7002', hex: '#817F68', name: 'Olive grey' },
  { ral: 'RAL 7003', hex: '#7A7B6D', name: 'Moss grey' },
  { ral: 'RAL 7004', hex: '#9EA0A1', name: 'Signal grey' },
  { ral: 'RAL 7005', hex: '#6B716F', name: 'Mouse grey' },
  { ral: 'RAL 7006', hex: '#756F61', name: 'Beige grey' },
  { ral: 'RAL 7008', hex: '#746643', name: 'Khaki grey' },
  { ral: 'RAL 7009', hex: '#5B6259', name: 'Green grey' },
  { ral: 'RAL 7010', hex: '#575D57', name: 'Tarpaulin grey' },
  { ral: 'RAL 7011', hex: '#555D61', name: 'Iron grey' },
  { ral: 'RAL 7012', hex: '#596163', name: 'Basalt grey' },
  { ral: 'RAL 7013', hex: '#555548', name: 'Brown-grey' },
  { ral: 'RAL 7015', hex: '#51565C', name: 'Slate grey' },
  { ral: 'RAL 7016', hex: '#373F43', name: 'Anthracite grey' },
  { ral: 'RAL 7021', hex: '#2E3234', name: 'Black grey' },
  { ral: 'RAL 7022', hex: '#4B4D46', name: 'Umbra grey' },
  { ral: 'RAL 7023', hex: '#818479', name: 'Concrete grey' },
  { ral: 'RAL 7024', hex: '#474A50', name: 'Graphite grey' },
  { ral: 'RAL 7026', hex: '#374447', name: 'Granite grey' },
  { ral: 'RAL 7030', hex: '#939388', name: 'Stone grey' },
  { ral: 'RAL 7031', hex: '#5D6970', name: 'Blue grey' },
  { ral: 'RAL 7032', hex: '#B9B9A8', name: 'Pebble grey' },
  { ral: 'RAL 7033', hex: '#818979', name: 'Cement grey' },
  { ral: 'RAL 7034', hex: '#939176', name: 'Yellow grey' },
  { ral: 'RAL 7035', hex: '#CBD0CC', name: 'Light grey' },
  { ral: 'RAL 7036', hex: '#9A9697', name: 'Platinum grey' },
  { ral: 'RAL 7037', hex: '#7C7F7E', name: 'Dusty grey' },
  { ral: 'RAL 7038', hex: '#B4B8B0', name: 'Agate grey' },
  { ral: 'RAL 7039', hex: '#6B695F', name: 'Quartz grey' },
  { ral: 'RAL 7040', hex: '#9DA3A6', name: 'Window grey' },
  { ral: 'RAL 7042', hex: '#8F9695', name: 'Traffic grey A' },
  { ral: 'RAL 7043', hex: '#4E5451', name: 'Traffic grey B' },
  { ral: 'RAL 7044', hex: '#BDBDB2', name: 'Silk grey' },
  { ral: 'RAL 7045', hex: '#91969A', name: 'Telegrey 1' },
  { ral: 'RAL 7046', hex: '#82898E', name: 'Telegrey 2' },
  { ral: 'RAL 7047', hex: '#CFD0CF', name: 'Telegrey 4' },
  { ral: 'RAL 7048', hex: '#888175', name: 'Pearl mouse grey' },
  { ral: 'RAL 8000', hex: '#887142', name: 'Green brown' },
  { ral: 'RAL 8001', hex: '#9C6B30', name: 'Ochre brown' },
  { ral: 'RAL 8002', hex: '#7B5141', name: 'Signal brown' },
  { ral: 'RAL 8003', hex: '#80542F', name: 'Clay brown' },
  { ral: 'RAL 8004', hex: '#8F4E35', name: 'Copper brown' },
  { ral: 'RAL 8007', hex: '#6F4A2F', name: 'Fawn brown' },
  { ral: 'RAL 8008', hex: '#6F4F28', name: 'Olive brown' },
  { ral: 'RAL 8011', hex: '#5A3A29', name: 'Nut brown' },
  { ral: 'RAL 8012', hex: '#673831', name: 'Red brown' },
  { ral: 'RAL 8014', hex: '#49392D', name: 'Sepia brown' },
  { ral: 'RAL 8015', hex: '#633A34', name: 'Chestnut brown' },
  { ral: 'RAL 8016', hex: '#4C2F26', name: 'Mahogany brown' },
  { ral: 'RAL 8017', hex: '#44322D', name: 'Chocolate brown' },
  { ral: 'RAL 8019', hex: '#3F3A3A', name: 'Grey brown' },
  { ral: 'RAL 8022', hex: '#211F20', name: 'Black brown' },
  { ral: 'RAL 8023', hex: '#A65E2F', name: 'Orange brown' },
  { ral: 'RAL 8024', hex: '#79553C', name: 'Beige brown' },
  { ral: 'RAL 8025', hex: '#755C49', name: 'Pale brown' },
  { ral: 'RAL 8028', hex: '#4E3B31', name: 'Terra brown' },
  { ral: 'RAL 8029', hex: '#763C28', name: 'Pearl copper' },
  { ral: 'RAL 9001', hex: '#FDF4E3', name: 'Cream' },
  { ral: 'RAL 9002', hex: '#E7EBDA', name: 'Grey white' },
  { ral: 'RAL 9003', hex: '#F4F4F4', name: 'Signal white' },
  { ral: 'RAL 9004', hex: '#282828', name: 'Signal black' },
  { ral: 'RAL 9005', hex: '#0A0A0A', name: 'Jet black' },
  { ral: 'RAL 9006', hex: '#A5A5A5', name: 'White aluminum' },
  { ral: 'RAL 9007', hex: '#8F8F8F', name: 'Grey aluminum' },
  { ral: 'RAL 9010', hex: '#FFFFFF', name: 'Pure white' },
  { ral: 'RAL 9011', hex: '#1C1C1C', name: 'Graphite black' },
  { ral: 'RAL 9016', hex: '#F6F6F6', name: 'Traffic white' },
  { ral: 'RAL 9017', hex: '#1E1E1E', name: 'Traffic black' },
  { ral: 'RAL 9018', hex: '#D7D7D7', name: 'Papyrus white' },
  { ral: 'RAL 9022', hex: '#9C9C9C', name: 'Pearl light grey' },
  { ral: 'RAL 9023', hex: '#828282', name: 'Pearl dark grey' },
] as const

export const SUGGESTED_RAL_COLORS = [
  RAL_COLORS.find((color) => color.ral === 'RAL 9010')!,
  RAL_COLORS.find((color) => color.ral === 'RAL 9003')!,
  RAL_COLORS.find((color) => color.ral === 'RAL 9005')!,
  RAL_COLORS.find((color) => color.ral === 'RAL 9006')!,
  RAL_COLORS.find((color) => color.ral === 'RAL 9007')!,
  RAL_COLORS.find((color) => color.ral === 'RAL 9001')!,
] as const
