import axios from 'axios'
import { type RalCode } from 'lib/ral-colors'
import z from 'zod'

import { type Model } from './useCoffinEditor'

const BASE_API_URL = 'https://cdesigner.nembedemand.dk/api'
const AUTH_COOKIE = 'abc123'

export type Design = {
  name?: string | null
  email?: string | null
  phone?: string | null

  model: Model | null
  handles: string | null

  topcolor: RalCode
  handlecolor: RalCode
  bottomcolor: RalCode
  plinthcolor: RalCode
  toppitchcolor: RalCode

  readonly?: boolean | null
  ordered?: boolean | null
  shareId?: string | null

  leftzoom: string | null
  leftoffsetx: string | null
  leftoffsety: string | null
  leftrotation: string | null
  leftmediafileid: string | null
  leftmedianame: string | null
  leftmediapxsize: string | null
  leftmediasize: string | null

  rightzoom: string | null
  rightoffsetx: string | null
  rightoffsety: string | null
  rightrotation: string | null
  rightmediafileid: string | null
  rightmedianame: string | null
  rightmediapxsize: string | null
  rightmediasize: string | null

  topzoom: string | null
  topoffsetx: string | null
  topoffsety: string | null
  toprotation: string | null
  topmediafileid: string | null
  topmedianame: string | null
  topmediapxsize: string | null
  topmediasize: string | null

  backzoom: string | null
  backoffsetx: string | null
  backoffsety: string | null
  backrotation: string | null
  backmediafileid: string | null
  backmedianame: string | null
  backmediapxsize: string | null
  backmediasize: string | null

  frontzoom: string | null
  frontoffsetx: string | null
  frontoffsety: string | null
  frontrotation: string | null
  frontmediafileid: string | null
  frontmedianame: string | null
  frontmediapxsize: string | null
  frontmediasize: string | null

  externalundertakerid?: number | null
}

// Define the RalCode and Model schemas
const RalCode = z
  .string()
  .regex(/^RAL \d{4}$/)
  .optional()
  .nullable()
const Model = z.enum(['orbit', 'flex', 'ligb']) // Adjust this as per your actual Model type

const stringNullableDefaultZero = z
  .string()
  .optional()
  .nullable()
  .default('0.0')

const ralNullableDefaultWhite = RalCode.optional()
  .nullable()
  .default('RAL 9010')

const ralNullableDefaultEmpty = RalCode.optional().nullable().default('')
const stringNullableDefaultEmpty = z.string().optional().nullable().default('')

// Define the Design schema
export const designSchema = z.object({
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),

  model: z.string().toLowerCase(),
  handles: z.string(),

  topcolor: ralNullableDefaultWhite,
  handlecolor: ralNullableDefaultEmpty,
  bottomcolor: ralNullableDefaultEmpty,
  plinthcolor: ralNullableDefaultEmpty,
  toppitchcolor: ralNullableDefaultEmpty,

  readonly: z.boolean().optional().nullable(),
  ordered: z.boolean().optional().nullable(),
  shareId: z.string().optional().nullable(),

  leftzoom: stringNullableDefaultZero,
  leftoffsetx: stringNullableDefaultZero,
  leftoffsety: stringNullableDefaultZero,
  leftrotation: stringNullableDefaultZero,
  leftmediafileid: stringNullableDefaultEmpty,
  leftmedianame: stringNullableDefaultEmpty,
  leftmediapxsize: stringNullableDefaultEmpty,
  leftmediasize: stringNullableDefaultZero,

  rightzoom: stringNullableDefaultZero,
  rightoffsetx: stringNullableDefaultZero,
  rightoffsety: stringNullableDefaultZero,
  rightrotation: stringNullableDefaultZero,
  rightmediafileid: stringNullableDefaultEmpty,
  rightmedianame: stringNullableDefaultEmpty,
  rightmediapxsize: stringNullableDefaultEmpty,
  rightmediasize: stringNullableDefaultZero,

  topzoom: stringNullableDefaultZero,
  topoffsetx: stringNullableDefaultZero,
  topoffsety: stringNullableDefaultZero,
  toprotation: stringNullableDefaultZero,
  topmediafileid: stringNullableDefaultEmpty,
  topmedianame: stringNullableDefaultEmpty,
  topmediapxsize: stringNullableDefaultEmpty,
  topmediasize: stringNullableDefaultZero,

  backzoom: stringNullableDefaultZero,
  backoffsetx: stringNullableDefaultZero,
  backoffsety: stringNullableDefaultZero,
  backrotation: stringNullableDefaultZero,
  backmediafileid: stringNullableDefaultEmpty,
  backmedianame: stringNullableDefaultEmpty,
  backmediapxsize: stringNullableDefaultEmpty,
  backmediasize: stringNullableDefaultZero,

  frontzoom: stringNullableDefaultZero,
  frontoffsetx: stringNullableDefaultZero,
  frontoffsety: stringNullableDefaultZero,
  frontrotation: stringNullableDefaultZero,
  frontmediafileid: stringNullableDefaultEmpty,
  frontmedianame: stringNullableDefaultEmpty,
  frontmediapxsize: stringNullableDefaultEmpty,
  frontmediasize: stringNullableDefaultZero,
})

const headers = {
  'Content-Type': 'application/json',
  AuthCookie: AUTH_COOKIE,
}

export const useApi = () => {
  const uploadMedia = async (
    file: File,
    onUploadProgress?: (progress: number) => void,
  ) => {
    const url = `${BASE_API_URL}/media`
    const formData = new FormData()
    formData.append('file', file)
    return axios.post<{
      fileid: string
      filename: string
      message: string // ok
      url: string
    }>(url, formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        onUploadProgress?.(
          Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1)),
        )
      },
    })
  }

  const getMedia = (id: string) => {
    return `${BASE_API_URL}/media/${id}`
  }

  const createDesign = async (design: Design) => {
    const url = `${BASE_API_URL}/design`

    return axios.post<{
      designid: string
      message: string // ok
    }>(url, JSON.stringify(design), {
      headers,
    })
  }

  const updateDesign = async (id: string, design: Design, order?: boolean) => {
    const url = `${BASE_API_URL}/design/${id}/${order ? '1' : ''}`
    return axios.put<{
      designid: string
      message: string // ok
    }>(url, JSON.stringify(design), {
      headers,
    })
  }

  const getDesign = async (id: string) => {
    const url = `${BASE_API_URL}/design/${id}`
    return axios
      .get<Design>(url, {
        headers,
      })
      .then((res) => res.data)
  }

  const getSuggestions = async () => {
    const url = `${BASE_API_URL}/suggestions`
    return axios
      .get<{
        message: string // ok
        results: {
          designId: string
          previewImageUrl: string
          title: string
          type: string
        }[]
      }>(url, {
        headers,
      })
      .then((res) => res.data)
  }

  const shareDesign = async (designId: string, email: string) => {
    const url = `${BASE_API_URL}/sharedesign/${designId}`
    return axios.put<{
      message: string // ok or error message
    }>(url, JSON.stringify({ email }), {
      headers,
    })
  }

  const getUndertakers = async () => {
    const url = `${BASE_API_URL}/undertakers`
    return axios
      .get<{
        isthisneeded: boolean
        message: string // ok or error message
        results: {
          address: string
          city: string
          id: number
          name: string
          phone: string
          zip: string
          zipcity: string
        }[]
      }>(url, {
        headers,
      })
      .then((res) => res.data)
  }

  return {
    uploadMedia,
    getMedia,
    createDesign,
    updateDesign,
    getDesign,
    getSuggestions,
    shareDesign,
    getUndertakers,
  }
}
