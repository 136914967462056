import { useCoffinEditor, type PrintSide } from 'hooks/useCoffinEditor'

import { Card } from './card'

export const TOP_POSITION = [0.0, 2.88, 1.86] as [number, number, number]
export const FRONT_POSITION = [0.0, 0.33, 2.64] as [number, number, number]
export const BACK_POSITION = [0.0, 0.33, -2.64] as [number, number, number]
export const RIGHT_POSITION = [2.29, 0.5, 1.25] as [number, number, number]
export const LEFT_POSITION = [-2.29, 0.5, 1.25] as [number, number, number]

export const SideSelector = (p: { close: () => void }) => {
  const {
    currentPrintSide,
    setCurrentPrintSide,
    updateCameraPosition,
    prints,
    printPanelIsOpen,
    setPrintPanelIsOpen,
  } = useCoffinEditor()

  const onClick = async (position: PrintSide) => {
    setCurrentPrintSide(position)
    setPrintPanelIsOpen(true)
    p.close()

    switch (position) {
      case 'Top':
        await updateCameraPosition(TOP_POSITION)
        break
      case 'Front':
        await updateCameraPosition(FRONT_POSITION)
        break
      case 'Bagside':
        await updateCameraPosition(BACK_POSITION)
        break
      case 'Højre side':
        await updateCameraPosition(RIGHT_POSITION)
        break
      case 'Venstre side':
        await updateCameraPosition(LEFT_POSITION)
        break
    }
  }

  return (
    <div className='p-3'>
      <div className='grid grid-cols-2 gap-2 lg:grid-cols-5'>
        {['Højre side', 'Venstre side', 'Top', 'Front', 'Bagside'].map(
          (side, i) => {
            const print = prints[side as PrintSide]

            return (
              <Card
                key={i}
                title={side as PrintSide}
                isActive={currentPrintSide === side && printPanelIsOpen}
                onClick={() => onClick(side as PrintSide)}
                imageUrl={print?.fileItem?.thumbnailUrl}
              >
                <div className='flex aspect-video items-center justify-center border-b bg-stone-50 text-xs opacity-50'></div>
              </Card>
            )
          },
        )}
      </div>
    </div>
  )
}
