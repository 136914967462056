import { useMemo } from 'react'
import type * as THREE from 'three'

export const useGeometry = (objs: THREE.Group<THREE.Object3DEventMap>[]) => {
  return useMemo(() => {
    return objs.map((obj) => {
      let g
      obj.traverse((c) => {
        if (c.type === 'Mesh') {
          const _c = c
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
          g = (_c as any).geometry
        }
      })
      return g
    })
  }, [objs])
}
