import { Dialog, DialogContent, DialogTrigger } from 'components/ui/dialog'
import { cn } from 'lib/utils'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'

import { Button } from '../ui/button'

export const MenuDropDownDialog = (p: {
  label: string
  content: React.ReactNode | ((close: () => void) => React.ReactNode)
  preview?: React.ReactNode
  value?: string
  popoverClassName?: string
  buttonClassName?: string
  align?: 'start' | 'center' | 'end'
  readonly?: boolean
}) => {
  const [open, setOpen] = useState(false)

  const CustomButton = () => (
    <Button
      variant={open ? 'default' : 'ghost'}
      className={cn(
        'h-auto w-full justify-start rounded-full p-2 pr-0',
        p.buttonClassName,
      )}
      onClick={() => setOpen(!open)}
    >
      <div
        className={cn(
          'mr-[10px] max-h-12 min-h-12 min-w-12 max-w-12 overflow-hidden rounded-full',
        )}
      >
        <div className='h-12 w-12'>{p.preview}</div>
      </div>
      <div className='w-full flex-col text-start'>
        <div className='text-[15px]'>{p.label}</div>
        <div className='-mt-1 font-normal opacity-60'>{p.value}</div>
      </div>
      <div className='ml-3 flex'>
        <ChevronDown
          className={cn(
            'max-h-[22px] min-h-[22px] min-w-[22px] max-w-[22px] transition-transform',
            open && 'rotate-180',
          )}
        />
        <div className='min-h-4 min-w-4' />
      </div>
    </Button>
  )

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <CustomButton />
      </DialogTrigger>
      <DialogContent
        className={cn(
          'm-3 ml-0 w-80 rounded-xl p-0 shadow-lg',
          p.popoverClassName,
        )}
      >
        <div className={cn(p.readonly && 'pointer-events-none opacity-50')}>
          {typeof p.content === 'function'
            ? p.content(() => setOpen(false))
            : p.content}
        </div>
      </DialogContent>
    </Dialog>
  )
}
