import { useState } from 'react'
import { toast } from 'sonner'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { useDesignCrud } from 'hooks/useDesignCrud'
import { EasyForm } from './easy-form'

type DesignInfo = {
  id: string
}

export const GetDesignDialog = (p: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { getDesign } = useDesignCrud()

  const onSubmit = async (values: Partial<DesignInfo>) => {
    if (!values.id) {
      toast.error('Indtast venligst et design ID')
      return
    }
    setIsLoading(true)
    try {
      const success = await getDesign(values.id)
      if (success) {
        p.setIsOpen(false)
      }
    } catch (e) {
      console.error(e)
      toast.error("Kunne ikke hente design. Prøv at tjekke design ID'et")
    }

    setIsLoading(false)
  }

  return (
    <AlertDialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Hent dit design</AlertDialogTitle>
          <AlertDialogDescription>
            <div className='mb-4'>
              For at hente dit design, skal du indtaste designets ID.
            </div>
            <EasyForm<DesignInfo>
              formFields={{
                id: { type: 'input', label: 'Design ID' },
              }}
              onSubmit={onSubmit}
              loading={isLoading}
              submitButtonText='Hent'
              cancelButtonText='Annuller'
              onCancel={() => p.setIsOpen(false)}
            />
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  )
}
