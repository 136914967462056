import { useQuery } from '@tanstack/react-query'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Table, TableBody, TableCell, TableRow } from 'components/ui/table'
import { useApi } from 'hooks/useApi'
import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { useConfirm } from 'hooks/useConfirm'
import { useDesignCrud } from 'hooks/useDesignCrud'

import { CurrentDesignRows } from './current-design-rows'
import { Button } from './ui/button'

export const OrderDesignDialog = (p: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { getUndertakers } = useApi()

  const { data: undertakers } = useQuery({
    queryKey: ['undertakers'],
    queryFn: getUndertakers,
  })

  const { latestDesignFromServer, activeDesignId } = useCoffinEditor()
  const { confirm } = useConfirm()

  const { updateDesign, isSavingDesign, getDesign } = useDesignCrud()

  const onOrderClick = async () => {
    confirm({
      title: 'Indsend design',
      message: `Vi vil tage kontakt til dig hurtigst muligt, når vi har modtaget dit design. 

      Vil du indsende det nu?`,
      confirmText: 'Indsend',
      onConfirm: async (cb) => {
        await updateDesign({ order: true }, () => p.setIsOpen(false))
        activeDesignId && (await getDesign(activeDesignId))
        cb()
      },
    })
  }

  const selectedUndertaker = undertakers?.results.find(
    (u) => u.id === latestDesignFromServer?.externalundertakerid,
  )

  return (
    <AlertDialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Indsend</AlertDialogTitle>
          <AlertDialogDescription>
            <div className='mb-4'>
              Bekræft venligst dine oplysninger og design. Vi vil kontakte dig
              hurtigst muligt, når du har indsendt.
            </div>
            <Table className='mb-4'>
              <TableBody>
                <TableRow>
                  <TableCell className='font-bold'>Navn</TableCell>
                  <TableCell>{latestDesignFromServer?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='font-bold'>Email</TableCell>
                  <TableCell>{latestDesignFromServer?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className='font-bold'>Telefonnummer</TableCell>
                  <TableCell>{latestDesignFromServer?.phone}</TableCell>
                </TableRow>
                {undertakers?.isthisneeded && (
                  <TableRow>
                    <TableCell className='font-bold'>Bedemand</TableCell>
                    <TableCell>
                      {selectedUndertaker?.name ?? 'Ikke fundet'}
                    </TableCell>
                  </TableRow>
                )}
                <CurrentDesignRows />
              </TableBody>
            </Table>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Annuler</AlertDialogCancel>
          <Button loading={isSavingDesign} onClick={onOrderClick}>
            Bestil
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
