import { useEffect } from 'react'

import { useCoffinEditor } from 'hooks/useCoffinEditor'
import { useDesignCrud } from 'hooks/useDesignCrud'

export const GetDesignFromPersistedId = () => {
  const { getDesign } = useDesignCrud()
  const { activeDesignId, latestDesignFromServer } = useCoffinEditor()

  useEffect(() => {
    if (activeDesignId && !latestDesignFromServer) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getDesign(activeDesignId)
    }
  }, [activeDesignId, latestDesignFromServer])

  return null
}
